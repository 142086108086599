import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';

const Footer = ()=>{
    const history = useHistory();
    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;
    const Year = new Date().getFullYear();
    return(
        <>
        <div className="footer">
            <div className="footer__item">
                <div className="footer__title">{lang.footPartners}</div>
                <div className="footer__link" onClick={()=>history.push('/mitra/transporter')}>Transporter</div>
                <div className="footer__link" onClick={()=>history.push('/mitra/shipper')}>Shipper</div>
            </div>

            <div className="footer__item">
                <div className="footer__title">{lang.footCompany}</div>
                <div className="footer__link" onClick={()=>history.push('/tentang')}>{lang.footAbout}</div>
                <a href="https://www.linkedin.com/company/mostrans/" style={{textDecoration:'none',color:'white'}}
                rel="noopener noreferrer" target="_blank">
                    <div className="footer__link" >{lang.footSosmed}</div>
                </a>
            </div>
            <div className="footer__item">
                <div className="footer__title">{lang.footSupport}</div>
                <div className="footer__link" onClick={()=>history.push('/kontak')}>{lang.footContact}</div>
                <div className="footer__link" onClick={()=>history.push('/kontak')}>{lang.footHelp}</div>
                <div className="footer__link" onClick={()=>history.push('/syarat-ketentuan')}>{lang.footTerms}</div>
                <div className="footer__link" onClick={()=>history.push('/privasi')}>{lang.footPrivacy}</div>
            </div>
            <div className="footer__item footer__end">
                <div className="footer__title">MOSTRANS</div>
                <div className="footer__link"> Jl. Pulomas Selatan Kav. No.3</div>
                <div className="footer__link">Kec. Pulo Gadung</div>
                <div className="footer__link">Jakarta 13210, Indonesia</div>
                <div className="footer__link">021-46823130</div>
            </div>
            <div className="footer__extra"></div>
        </div>
        <div className="copyright">
            <div className="copyright__text">
                Copyright {Year} by PT Mostrans Global Digilog
            </div>
        </div>
        </>
    )
}

export default Footer;