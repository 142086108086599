import React, { useContext, useEffect } from 'react';
import {solusi1,solusi2,solusi3,solusi4} from '../../assets'
import { LanguageContext } from '../../context/LanguageContext';

const Solusi = ()=>{
    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;
    useEffect(()=>{
        window.scroll({
            behavior:'smooth',
            top:0
        })
    },[])
    return(
        <>
        <div className="head head-solusi">
            <div className="head__title">{lang.navSolutions}</div>
            <div className="head__info" dangerouslySetInnerHTML={{__html:lang.solusiHero}}>
            </div>
        </div>
        <div className="solusi">
            <div className="solusi__item">
                <div className="solusi__content">
                    <div className="solusi__title">Full Truck Marketplace</div>
                    <div className="solusi__info" dangerouslySetInnerHTML={{__html:lang.solusiFullTruck}}>
                    </div>
                </div>
                <div className="solusi__img">
                    <img src={solusi1.default} alt=""/>
                </div>
            </div>

            <div className="solusi__item solusi--item">
                <div className="solusi__img">
                    <img src={solusi2.default} alt=""/>
                </div>
                <div className="solusi__content">
                    <div className="solusi__title">Consolidation Marketplace</div>
                    <div className="solusi__info" dangerouslySetInnerHTML={{__html:lang.solusiConsolidation}}>
                    </div>
                </div>
               
            </div>

            <div className="solusi__item">
                <div className="solusi__content">
                    <div className="solusi__title">Software as a Service</div>
                    <div className="solusi__info" dangerouslySetInnerHTML={{__html:lang.solusiSoftwareService}}>
                    </div>
                </div>
                <div className="solusi__img">
                    <img src={solusi3.default} alt=""/>
                </div>
            </div>

            <div className="solusi__item solusi--item">
                <div className="solusi__img">
                    <img src={solusi4.default} alt=""/>
                </div>
                <div className="solusi__content">
                    <div className="solusi__title">Supply Chain Financing</div>
                    <div className="solusi__info" dangerouslySetInnerHTML={{__html:lang.solusiSupplyChainFinancing}}>
                    </div>
                </div>
               
            </div>
        </div>
        </>
    )
}

export default Solusi;