import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const MitraForm = ()=>{
    const history = useHistory();
    const query = useQuery();

    const [form,setForm] = useState({
        nama:'',
        perusahaan:'',
        handphone:'',
        password:'',
        email:'',
        konfirmasi:'',
        daftar:''
    })

    useEffect(()=>{
        if(query.get('val')){
            setForm({...form,daftar:query.get('val')})
        }
        window.scroll({
            behavior:'smooth',
            top:0
        })
    },[]);

    const onSubmit = (e)=>{
        e.preventDefault();
        console.log(form);
        if(form.password !== form.konfirmasi){
            alert('Konfirmasi password tidak sesuai!');
        }else{
            //SUBMIT TO SERVER
            history.push('/terima-kasih-daftar')
        }
        
    }
    return(
        <div className="mform">
            <form className="mform__container" onSubmit={onSubmit}>
                <div className="c-form__divide">
                    <div>
                        <div className="input-group">
                            <label>Nama</label>
                            <input type="text" placeholder="Nama Anda"
                            value={form.nama}
                            required
                            onChange={e=>setForm({...form,nama:e.target.value})}
                            />
                        </div>
                        <div className="input-group mt-2">
                            <label>Handphone</label>
                            <input type="number" placeholder="Nomor Handphone"
                            value={form.handphone}
                            onChange={e=>setForm({...form,handphone:e.target.value})}
                            required
                            />
                        </div>
                        <div className="input-group mt-2" >
                            <label>Email</label>
                            <input type="email" placeholder="Alamat Email"
                            value={form.email}
                            onChange={e=>setForm({...form,email:e.target.value})}
                            required
                            />
                        </div>
                    </div>
                    <div>
                        <div className="input-group">
                            <label>Perusahaan</label>
                            <input type="text" placeholder="Nama Perusahaan"
                            value={form.perusahaan}
                            onChange={e=>setForm({...form,perusahaan:e.target.value})}
                            required
                            />
                        </div>
                        <div className="input-group mt-2">
                            <label>Password</label>
                            <input type="password" placeholder="Password Anda"
                            value={form.password}
                            onChange={e=>setForm({...form,password:e.target.value})}
                            required
                            />
                        </div>
                        <div className="input-group mt-2">
                            <label>Konfirmasi Password</label>
                            <input type="password" placeholder="Ulangi Password"
                            value={form.konfirmasi}
                            onChange={e=>setForm({...form,konfirmasi:e.target.value})}
                            required
                            />
                        </div>
                    </div>
                </div>
                <div className="mform__btm">
                    <div className="input-group">
                        <label>Daftar Sebagai</label>
                        <select value={form.daftar} onChange={(e)=>setForm({...form,daftar:e.target.value})} required>
                            <option value="shipper">Shipper</option>
                            <option value="transporter">Transporter</option>
                        </select>
                    </div>
                    <button className="mform__btn" type="submit">Daftar</button>
                </div>
            </form>
        </div>
    )
}

export default MitraForm;