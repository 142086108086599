import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';

const Mitra = ()=>{
    const history = useHistory();
    useEffect(()=>{
        window.scroll({
            behavior:'smooth',
            top:0
        })
    },[]);

    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;
    
    return(
        <div className="mitra">
            <div className="mitra__item mitra--shipper">
                <div className="mitra__title">SHIPPER</div>
                <div className="mitra__info">
                    {lang.mitraShipperChoice}
                </div>
                
                <button className="mitra__btn" onClick={()=>history.push('/mitra/shipper')}>{lang.btnReadMore}</button>
                {/* <a className="mitra__btn" href="https://mostrans.co.id/login/SignUpShipper">Selengkapnya</a> */}
            </div>
            <div className="mitra__item mitra--transporter">
                <div className="mitra__title">TRANSPORTER</div>
                <div className="mitra__info">
                    {lang.mitraTransporterChoice}
                </div>
                <button className="mitra__btn" onClick={()=>history.push('/mitra/transporter')}>{lang.btnReadMore}</button>
                {/* <a className="mitra__btn" href="https://mostrans.co.id/login/SignUpTransporter">Selengkapnya</a> */}
            </div>
        </div>
    )

}

export default Mitra;