import React, { Component, createContext } from 'react';

export const LanguageContext = createContext();

class LanguageContextProvider extends Component{
    state={
        isEng:true,
        eng:{
            vision:'VISION',
            mission:'MISSION',
            ourVision:'Our Vision',
            ourMission: 'Our Mission',
            visionContent:'To be a preferred healthcare digital transportation platform in Indonesia.',
            missionContent:'To build healthcare supply chain digital eco-system to provide high quality of transportation service for healthier Indonesia.',
            navHome:'Home',
            navAbout:'About Us',
            navSolutions:'Solutions',
            navPartner:'Partner',
            navContact : 'Contact',
            navCareer:'Career',
            bannerTruck:`The 1st digital transportation platform <br/>
            in Indonesia to connect healthcare supply chain eco-system`,
            btnJoin : 'Join Us',
            bannerKarirTitle:'CAREER',
            bannerKarir:`let's join us and take your career to the next level`,
            btnDetails:'More details',
            bannerBisnisTitle:'BUSINESS',
            bannerBisnis:'Do you have a product to ship or are you a courier business owner? Join us now and grow your business with us',
            btnRegister:'Register',

            homeAbout_p1:'Online transportation management platform to connect and collaborate shippers, transporters, and receivers on a real-time basis.',
            homeAbout_p2:'Integrated information and real time visibility of logistic process to provide operational excellence.',
            btnReadMore:'Read More',
            homeAboutGrowing:'Our Growing Partners',
            txtShipper:'Shippers',
            txtTransporters:'Transporters',
            txtPoints:'Points',
            txtTrucks:'Trucks',
            txtOF:'Fullfilment Rate',
            txtOY:'Orders Yearly',

            homeSolutionsTitle:'Our Solutions',
            homeSolution_p1:'On-demand digital transportation platform to connect shippers and transporters on a real-time basis',
            homeSolution_p2:'A transportation management system to integrate shipments and trucks throughout your ecosystem network on real-time basis with comprehensive business intelligence',

            txtLiputan:'MOSTRANS on News',
            homeContactTitle:'Any inquiries? Get in Touch with us!',
            homeContactTxt:"Don't hesitate to contact us, we will be right with you soon.",

            labelName:'Name',
            labelPerusahaan:'Company',
            labelBusinessPart:'Business Partner',
            labelJudul:'Title',
            labelPesan:'Message',
            labelPhone:'Phone Number',
            btnKirim:'Send',

            footPartners:'Partners',
            footCompany:'Company',
            footSupport:'Support',
            footAbout:'About Us',
            footSosmed:'Social Media',
            footContact:'Contact Us',
            footHelp:'Help',
            footTerms:'Terms & Conditions',
            footPrivacy:'Privacy Policy',

            tentangHero:'MOSTRANS covers delivery throughout Indonesia with in-land and sea <br/> transportation with room, cool, and cold temperature shipment <br/> condition based on CDOB & CDAKB standard.',

            tentangOnDemand:'Create delivery order anytime & anywhere. We’re always ready to serve your order',
            tentangEaseOfMind:'Easy transaction process & full visibility of truck location & order status',
            tentangLiveChatSupport:'Any inquiries? We’re always ready to serve you',
            tentangCompetitveRates:'Wide (varieties/options) of Transporter. Get the best service based on agreed rate',
            tentangControlTower:'Want to improve your warehouse performance? We provide inbound & outbound vehicle monitoring',
            tentangCommandCenter:'Our agents proactively monitor deliveries & engage in encountered operational issues',
            tentangERPIntegration : 'Already uses ERP? We support ERP to MOSTRANS integration for automatic order process',
            tentangDirectStore:'Our system fully supports Multi Pick, Multi Drop, and even Multi Pick & Multi Drop deliveries',
            tentangWideVariety:'Starting from CDE, CDD, Fuso, Wing Box, Built Up, Palletize, and even Tronton. Both Ambient & verified Reefer units are at your disposal',
            tentangDataAnalytics:'Gain unseen insight with our performance report for your business development',
            tentangReliable:'Our selected and routinely audited Transporters ensures safe delivery',

            solusiHero:`Using advanced technology and great implementation, MOSTRANS provides <br/>
            end to end integration, connectivity, and collaboration between shippers and <br/> transporters on a real time basis`,

            solusiFullTruck:`On-demand digital transportation platform to connect shippers and transporters on a real-time basis. 
            <br/><br/>
            Shippers and Transporters can access information, get notification, shipment journey monitoring, and receive performance report.
            `,
            solusiConsolidation:`Maximize your Partial/Retail shipment with our available fixed schedule. As easy as booking a plane ticket. 
            <br/><br/>
            Collaborated Shippers and Transporters benefits from cost, utilization, and time efficiency
            `,
            solusiSoftwareService:`A transportation management system to integrate shipments and trucks throughout your ecosystem network on real-time basis with comprehensive business intelligence
            <br/><br/>
            Digitalizing transportation business process that enable visibility and transparency for greater effectiveness and efficiency that increase competitive advantage in building sustainable business.
            `,
            solusiSupplyChainFinancing:`MOSTRANS helps provide solutions for Transporters, namely by providing working capital financing facilities to achieve cash flow stability`,

            mitraShipperChoice:`Are you a Goods Owner or Sender?`,
            mitraTransporterChoice:`Are you a Trucks Owner or Company?`,

            kontakHeroTitle:`Do you have questions?`,
            kontakHeroInfo:`Contact us, our Customer Care is ready to help you.`,

            shipperHero:`Now you can focus on business development. <br/>
            Trust your shipment to us.`,
            
            shipperFixedRate:`FIXED RATE`,
            shipperVisibility:`SHIPMENT VISIBILITY & TRUCK ARRIVAL`,
            shipperReal:`REAL & ACTUAL PERFORMANCE`,
            shipper24:`24/7 COMMAND CENTER`,
            shipperConsolidate:`CONSOLIDATE YOUR SHIPMENT`,
            txtJoin:`JOIN MOSTRANS`,
            txtJoinInfo:`You can focus on business development <br/>
            Trust your shipment to us`,

            shipperFixedRateInfo:`Rate agremeent first, enjoy order convenience later. Rates will never change without your consent`,
            shipperVisibilityInfo:`Accurate truck arrival to prepare manpower, equipment and premises. Enjoy efficient and stress-free operations.`,
            shipperRealInfo:`Get real-time reports in full for performance assessment and the development of your internal processes.`,
            shipper24Info:`We are always ready to monitor and keep your shipments on time & safely to their destination.`,
            shipperConsolidateInfo:`Increase shipping cost efficiency by using our Consolidation feature for partial loads (LTL) with a fixed schedule.`,

            transporterHero:`Maximize your income with additional orders <br/>
            and high truck productivity.`,

            transporterAdditional:`ADDITIONAL ORDERS`,
            transporterTrack:`TRACK YOUR SHIPPING AND DRIVERS`,
            transporterFaster:`FASTER PAYMENT`,
            transporterAdditionalInfo:`Receive additional orders and maximize your trucks by return load improvement from the MOSTRANS Shipper.`,
            transporterTrackInfo:`You can monitor locations, real time status, and see the entire history of each shipment at your fingertips`,
            transporterFasterInfo:`Dreaming to stabilize your cash flow? Enjoy accelerated payment exclusive for MOSTRANS Transporter`,

            karirTitle:`DISCOVER YOUR JOURNEY WITH US`,
            karirInfo:`Are you looking for opportunities to develop and succeed? Are you a College / Diploma Student / Graduate or <br/>
            Seasoned Professional? Explore how You can contribute MOSTRANS to provide high quality of transportation<br/> service for healthier Indonesia`,
            txtPrivasi:'PRIVACY POLICY',
            txtSyarat:'TERMS AND CONDITIONS',

            txtThx:'THANK YOU',
            btnBackToHome:'Back to Home',
            txtThxPesan:'Our team will immediately reply to your message.',
            txtThxDaftar:`Thanks for joining us. <br/>
            We will immediately validate your data <br/>
            and will contact you shortly`
        },
        ind:{
            vision:'VISI',
            mission:'MISI',
            ourVision:'Visi Kami',
            ourMission: 'Misi Kami',
            visionContent:'Menjadi platform transportasi digital terpercaya untuk bidang Kesehatan di Indonesia.',
            missionContent:'Membangun ekosistem digital supply chain kesehatan untuk menyediakan layanan transportasi berkualitas tinggi untuk Indonesia yang lebih sehat.',
            navHome:'Beranda',
            navAbout:'Tentang Kami',
            navSolutions:'Solusi',
            navPartner:'Mitra',
            navContact : 'Kontak',
            navCareer:'Karir',
            bannerTruck:`The 1st digital transportation platform <br/>
            in Indonesia to connect healthcare supply chain eco-system`,
            btnJoin : 'Gabung',
            bannerKarirTitle:'KARIR',
            bannerKarir:`Mari bergabung bersama kami dan kembangkan karir Anda ke level berikutnya`,
            btnDetails:'Selengkapnya',
            bannerBisnisTitle:'BISNIS',
            bannerBisnis:'Anda pemilik barang atau pemilik usaha Transportasi? Mari kembangkan bisnis anda bersama kami',
            btnRegister:"Daftar",

            homeAbout_p1:'Platform manajemen transportasi online yang menghubungkan Shipper, Transporter, dan Penerima Barang secara real-time',
            homeAbout_p2:'Informasi terintegrasi dan visibilitas proses logistik untuk memberikan kemudahan & kelancaran pengiriman barang',
            btnReadMore:'Selengkapnya',
            homeAboutGrowing:'Partner yang terus bertumbuh',
            txtShipper:'Shipper',
            txtTransporters:'Transporter',
            txtPoints:'Point',
            txtTrucks:'Truck',
            txtOF:'Tingkat Terpenuhi',
            txtOY:'Pesanan Per Tahun',

            homeSolutionsTitle:'SOLUSI UNTUK ANDA',
            homeSolution_p1:'Platform transportasi digital yang menghubungkan Shipper & Transporter secara real-time',
            homeSolution_p2:'Sistem manajemen transportasi untuk integrasi pengiriman dan truk ke seluruh jaringan ekosistem Anda secara real-time dengan kecerdasan bisnis yang komprehensif',

            txtLiputan:'Liputan MOSTRANS di Media',
            homeContactTitle:'ANDA MEMILIKI PERTANYAAN?',
            homeContactTxt:'Silahkan hubungi kami sekarang, kami siap membantu Anda.',

            labelName:'Nama',
            labelPerusahaan:'Perusahaan',
            labelBusinessPart:'Business Partner',
            labelJudul:'Judul',
            labelPesan:'Pesan',
            labelPhone:'Telephone',
            btnKirim:'Kirim Pesan',

            footPartners:'Mitra',
            footCompany:'Perusahaan',
            footSupport:'Pendukung',
            footAbout:'Tentang Kami',
            footSosmed:'Media Sosial',
            footContact:'Hubungi Kami',
            footHelp:'Bantuan',
            footTerms:'Syarat & Ketentuan',
            footPrivacy:'Kebijakan Privasi',

            tentangHero:'MOSTRANS menjangkau pengiriman moda darat dan laut baik suhu <br/> ruang maupun berpendingin yang mengacu pada standar CDOB & <br/> CDAKB ke seluruh Indonesia ',

            tentangOnDemand:'Buat pesanan kapanpun & dimanapun. Kami selalu siap untuk memenuhi pesanan anda',
            tentangEaseOfMind:'Nikmati kemudahan bertransaksi dan monitor penuh atas lokasi truk serta status pesanan anda',
            tentangLiveChatSupport:'Ada yang ingin disampaikan? Kami selalu siap untuk melayani anda',
            tentangCompetitveRates:'Kami memiliki banyak Transporter. Dapatkan pelayanan terbaik sesuai dengan kesepakatan harga',
            tentangControlTower:`Ingin mengembangkan performa gudang anda? Monitor inbound & outbound anda segera`,
            tentangCommandCenter:'Kami memonitor pengiriman dan menyelesaikan kendala di perjalanan',
            tentangERPIntegration : 'Sudah menggunakan ERP? Kami siap membantu integrasi ERP – MOSTRANS untuk automatic order',
            tentangDirectStore:'Sistem kami siap untuk pesanan Multi Pick, Multi Drop, dan bahkan Multi Pick & Multi Drop anda',
            tentangWideVariety:'Mulai dari CDE, CDD, Fuso, Wing Box, Built Up, Palletize, Tronton, dan bahkan Container. Baik suhu ruang dan pendingin yang terverifikasi siap melayani anda',
            tentangDataAnalytics:'Dapatkan insight baru dengan laporan kinerja kami untuk pengembangan bisnis anda',
            tentangReliable:'Kami menyeleksi dan rutin mengaudit Transporter kami untuk menjamin pengiriman yang aman',

            solusiHero:`Dengan kemajuan teknologi dan pengalaman kami,
            MOSTRANS <br/> menyediakan  integrasi, konektivitas, dan  kolaborasi antara <br/> Shipper & Transporter`,

            solusiFullTruck:`MOSTRANS, Platform transportasi digital yang menghubungkan Shipper dan Transporter secara real-time.
            <br/><br/>
            Akses informasi, notifikasi, monitor pengiriman, dan laporan kinerja menggunakan sistem kami
            `,
            solusiConsolidation:`Maksimalkan pengiriman Parsial / Retail Anda dengan jadwal dari kami. Semudah memesan tiket pesawat.
            <br/><br/>
            Dapatkan keuntungan reduksi biaya, utilisasi, dan efisiensi waktu dengan kolaborasi Shipper & Transporter
            `,
            solusiSoftwareService:`Sistem manajemen transportasi untuk integrasi pengiriman dan truk ke seluruh jaringan ekosistem Anda secara real-time dengan kecerdasan bisnis yang komprehensif
            <br/><br/>
            Digitalisasi proses bisnis transportasi yang memungkinkan visibilitas dan transparansi untuk efektivitas dan efisiensi yang lebih tinggi untuk meningkatkan keunggulan kompetitif dalam membangun bisnis yang berkelanjutan
            `,
            solusiSupplyChainFinancing:`MOSTRANS membantu memberikan solusi bagi Transporter dengan memberikan fasilitas pembiayaan modal kerja untuk mencapai stabilitas arus kas`,

            mitraShipperChoice:`Apakah anda pemilik atau pengirim barang?`,
            mitraTransporterChoice:`Apakah anda pengusaha / pemilik jasa kendaraan?`,

            kontakHeroTitle:`Anda Memiliki Pertanyaan?`,
            kontakHeroInfo:`Hubungi kami, Customer Care kami siap membantu anda.`,

            shipperHero:`Sekarang anda dapat fokus pada pengembangan bisnis. <br/>
            Urusan pengiriman percayakan saja kepada kami.`,

            shipperFixedRate:`HARGA PASTI`,
            shipperVisibility:`PANTAU PENGIRIMAN & KEDATANGAN TRUCK`,
            shipperReal:`PERFORMA & KINERJA AKTUAL`,
            shipper24:`24/7 COMMAND CENTER`,
            shipperConsolidate:`Konsolidasi Muatan Anda`,
            txtJoin:`BERGABUNG BERSAMA MOSTRANS`,
            txtJoinInfo:`Anda dapat fokus pada pengembangan bisnis, <br/>
            urusan pengiriman percayakan kepada kami.`,

            shipperFixedRateInfo:`Sepakati penawaran kami dahulu, nikmati kemudahan kemudian. Harga tidak akan berubah tanpa persetujuan anda.`,
            shipperVisibilityInfo:`Kedatangan pengiriman akurat untuk menyiapkan tenaga kerja, peralatan, dan ruangan. Nikmati fitur tambahan yang memudahkan aktivitas anda.`,
            shipperRealInfo:`Dapatkan laporan aktual lengkap untuk menilai kinerja kami ataupun rencana pengembangan internal anda.`,
            shipper24Info:`Kami siap memantau dan menjaga agar pengiriman anda tepat waktu dan aman sampai tujuan.`,
            shipperConsolidateInfo:`Tingkatkan efisiensi biaya pengiriman dengan menggunakan fitur konsolidasi kami untuk muatan parsial anda dengan jadwal keberangkatan yang pasti.`,

            transporterHero:`Maksimalkan pendapatan anda dengan tambahan order <br/>
            dan produktivitas truk yang tinggi.`,

            transporterAdditional:`DAPATKAN ORDER TAMBAHAN`,
            transporterTrack:`LACAK PENGIRIMAN DAN PENGEMUDI`,
            transporterFaster:`PERCEPATAN PEMBAYARAN`,

            transporterAdditionalInfo:`Tingkatkan keuntungan & utilisasi truk anda dengan pengembangan muatan balikan dari Shipper MOSTRANS`,
            transporterTrackInfo:`Dengan sentuhan jari, anda dapat memonitor setiap lokasi, status aktual dan seluruh historis dari setiap pengiriman`,
            transporterFasterInfo:`Ingin arus kas yang stabil? Nikmati percepatan pembayaran hanya untuk Transproter MOSTRANS`,

            karirTitle:`BERGABUNG BERSAMA KAMI`,
            karirInfo:`Mari bergabung bersama dengan kami, kembangkan karir anda ke level berikutnya.
            <br /> 
            Apakah anda mahasiswa, lulusan universitas, atau profesional berpengalaman, temukan bagaimana anda dapat <br/> berkontribusi dengan berkarir di Mostrans.`,

            txtPrivasi:'KEBIJAKAN PRIVASI',
            txtSyarat:'SYARAT DAN KETENTUAN',

            txtThx:'TERIMA KASIH',
            btnBackToHome:'Kembali ke Beranda',
            txtThxPesan:'Team kami akan segera membalas pesan anda.',
            txtThxDaftar:`Terima kasih untuk bergabung bersama dengan kami.<br/>
            Kami akan segera memvalidasi data anda <br/>
            dan akan segera menghubungi anda.`
        }
    }

    toggleLanguageEng = () =>{
        this.setState({isEng: true});
    }

    toggleLanguageInd = () =>{
        this.setState({isEng: false});
    }

    render(){
        return(
            <LanguageContext.Provider value={{...this.state, 
            toggleLanguageEng:this.toggleLanguageEng,
            toggleLanguageInd:this.toggleLanguageInd}}>
                {this.props.children}
            </LanguageContext.Provider>
        )
    }
}

export default LanguageContextProvider;