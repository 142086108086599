import React, { useState } from 'react';
import {mailIcon,callIcon,waIcon} from '../../assets';
import { GoogleMap, LoadScript,Marker,InfoWindow  } from '@react-google-maps/api';

const MapContainer = ({className}) => {
    const [info,setInfo] = useState(false);
    
    const defaultCenter = {
      lat: -6.1727843, lng: 106.8772857
    }
    
    return (
       <LoadScript
         googleMapsApiKey='AIzaSyC_p7CNmy0eY93maq2LF0pYWOT1qEpTsfA'>
          <GoogleMap
            mapContainerClassName={className}
            zoom={15}
            center={defaultCenter}
          >
              <Marker key="MOSTRANS Head Office" position={defaultCenter} 
              onClick={()=>setInfo(true)}
              />
              {info?<InfoWindow
              position={{
                lat: -6.1727843, lng: 106.8772857
              }}
              
              clickable={true}
              onCloseClick={() => setInfo(false)}>
              <div className="infowindow">
                <p style={{fontWeight:'bold'}}>MOSTRANS Head Office</p>
                <br/>
                <p >Jl. Pulomas Selatan Kav. No.3, RT.3/RW.13. Kec. Pulo Gadung Kota Jakarta Timur Daerah Khusus Ibukota Jakarta 13210</p>
                <br/>
                <p><b>Kontak: </b>(021) 468-23-130</p>
              </div>
            </InfoWindow>:''}
          </GoogleMap>
       </LoadScript>
    )
  }
  

const Maps = ({top})=>{
    return(
        <div className="map-container" >
            {top ? <MapContainer className="map map--top" />: '' }
            <div className="contact-item">
                <img src={mailIcon.default} alt=""/>
                <p>Email</p>
                <div className="hub">care@mostrans.id</div>
            </div>
            <div className="contact-item">
                <img src={callIcon.default} alt=""/>
                <p>Call Center</p>
                <div className="hub">021 468 23 130</div>
            </div>
            <div className="contact-item">
                <img src={waIcon.default} alt=""/>
                <p>Whatsapp</p>
                <div className="hub">0812 9285 5237</div>
            </div>
            {!top ? <MapContainer className="map" /> : '' }
        </div>
    )
}

export default Maps;