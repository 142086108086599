import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';

const ListKarir = [
    {
        title:'Transportation Internal',
        content:'Usia maksimal 28 tahun, Pendidikan minimal Diploma, Kemampuan analitik dan pemecahan masalah baik, Gigih dan inovatif.'
    },
    {
        title:'Transportation Operational',
        content:'Usia maksimal 28 tahun, Pendidikan minimal Diploma, Kemampuan berkomunikasi dan pemasaran yang baik, Gigih dan inovatif.'
    },
    {
        title:'Vendor Management',
        content:'Usia maksimal 28 tahun, Pendidikan minimal Diploma, Kemampuan berkomunikasi dan pemasaran yang baik, Berdedikasi dan'
    },
    {
        title:'Business Development',
        content:'Usia maksimal 28 tahun, Pendidikan minimal Diploma, Kemampuan berkomunikasi dan pemasaran yang baik, Pantang menyerah dan mampu bekerja dibawah tekanan.'
    }
];

const Karir = ()=>{
    const history = useHistory();

    useEffect(()=>{
        window.scroll({
            behavior:'smooth',
            top:0
        })
    },[]);
    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;

    const List = ListKarir.map((karir,idx)=>{
        return <div className="karir__item" key={idx}>
        <div className="karir__left">
            <div className="karir--title">{karir.title}</div>
            <div className="karir--info">
                {karir.content}
            </div>
        </div>
        <button className="karir__btn" onClick={()=>history.push(`/karir/apply?val=${karir.title.toUpperCase()}`)}>Apply</button>
    </div>
    })
    
    return(
        <>
        <div className="head head-karir"></div>
        <div className="karir">
            <div className="karir__title">{lang.karirTitle}</div>
            <div className="karir__info" dangerouslySetInnerHTML={{__html:lang.karirInfo}}>
                
            </div>
            <div className="karir__items">
                <center>
			<a href='https://dev.mostrans.co.id/career/#/' target="_blank" className="karir__title">COMING SOON</a>
		</center>
                {/* {List} */}
            </div>
        </div>
        </>
    )
}

export default Karir;