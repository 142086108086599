import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
const Apply = ()=>{
    
    const query = useQuery();
    const history = useHistory();
    const [karir,setKarir] = useState('');

    useEffect(()=>{
        if(!query.get('val')){
            history.push('/karir');
            return;
        }
        window.scroll({
            behavior:'smooth',
            top:0
        })

        setKarir(query.get('val'));
    },[history,query])
    return(
        <>
        <div className="head head-karir"></div>
        <div className="karir">
            <div className="apply">
                <div className="apply__info">
                    <div className="apply__title">{karir}</div>
                    <div className="apply__posted">Posted date 12 Desember 2020</div>
                    <div className="apply__desc mt-2">Deskripsi Pekerjaan</div>
                    <ul>
                        <li>Bertanggung jawab terhadap keseluruhan proses dan informasi pengiriman barang dari gudang pusat ke seluruh cabang, memastikannya berjalan sesuai jadwal yang telah ditetapkan dengan menjaga kualitas produk dan pelayanan yang optimal serta tetap mengacu kepada efisiensi biayapengiriman.</li>
                        <li>Bertanggung jawab terhadap pengelolaan vendor pengiriman, mulai dari negosiasi harga, pembuatan dan pengajuan perjanjian kerjasama. </li>
                    </ul>
                    <div className="apply__desc mt-2">Kualifikasi Pekerjaan</div>
                    <ul>
                        <li>Minimal S1 semua jurusan</li>
                        <li>Memiliki pengalaman minimal 1 tahun di area Transportation Management dan/atau Logistic Operation Management</li>
                        <li>Memiliki kemampuan komunikasi dan negosiasi yang baik </li>
                        <li>Bersedia ditempatkan di Head Office - Bintaro</li>
                    </ul>
                </div>
                <div className="apply__form">
                    <div className="input-group">
                        <label>Nama</label>
                        <input type="text" placeholder="Nama Anda" />
                    </div>

                    <div className="input-group mt-2">
                        <label>Email</label>
                        <input type="email" placeholder="Alamat Email" />
                    </div>

                    <div className="input-group mt-2">
                        <label>Handphone</label>
                        <input type="number" placeholder="Nomor Handphone" />
                    </div>

                    <div className="input-group mt-2">
                        <label>Alamat</label>
                        <input type="text" placeholder="Alamat Tinggal" />
                    </div>

                    <div className="input-group mt-2">
                        <label>Resume<span style={{color:'red'}}>*</span></label>
                        <button className="apply__btn">Upload Resume</button>
                        <p className="apply__text">(PDF File only, maximum file size 2mb)</p>
                    </div>

                    <div className="input-group mt-2">
                        <button className="apply__btn">Kirim Lamaran</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Apply;