import React, { useContext, useEffect,  useState } from 'react';
import Flickity from 'react-flickity-component';
import ContactForm from '../../components/ContactForm/ContactForm';
import Maps from '../../components/Maps/Maps';
import {playBtn,laptop,
    liputanAntara,
    liputanBisnis,
    liputanGatra,
    liputanPowercom,
    liputanPressrelease,
    liputanRepublika,
    liputanSwa,
    liputanUpperline,
    liputanWarta,
    supportIcon
} from '../../assets';
import { useHistory } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { LanguageContext } from '../../context/LanguageContext';

const BannerTruck = ()=>{
    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;
    const history = useHistory();
    return(
        <section className="banner-truck">
            <div className="banner-truck__title">MOSTRANS</div>
            <div className="banner-truck__info" dangerouslySetInnerHTML={{__html:lang.bannerTruck}}>
            </div>
            <button className="banner-truck__btn" onClick={()=>history.push('/mitra')}>Join Us</button>
        </section>
    )
}

const BannerKarir = ()=>{
    const history = useHistory();
    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;
    return(
        <section className="banner-karir">
            <div className="banner-karir__container">
                <div className="banner-karir__title">{lang.bannerKarirTitle}</div>
                <div className="banner-karir__info">
                    {lang.bannerKarir}
                </div>
                <button className="banner-karir__btn" onClick={()=>history.push('/karir')}>{lang.btnDetails}</button>
            </div>
        </section>
    )
}

const BannerBisnis = ()=>{
    const history = useHistory();
    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;
    return(
        <section className="banner-bisnis">
            <div className="banner-bisnis__container">
                <div className="banner-bisnis__title">{lang.bannerBisnisTitle}</div>
                <div className="banner-bisnis__info">
                    {lang.bannerBisnis}
                </div>
                <button className="banner-bisnis__btn" onClick={()=>history.push('/mitra')}>{lang.btnRegister}</button>
            </div>
        </section>
    )
}

const Liputan = ({src,url})=>{
    return(
        <a href={url} rel="noopener noreferrer" target="_blank">
            <div className="liputan__item">
                <img src={src} alt=""/>
            </div>
        </a>
    )
}

const Beranda = ()=>{
    const [banner,setBanner] = useState(null);
    const [liputan,setLiputan] = useState(null);
    const [video,setVideo] = useState(false);
    const history = useHistory();

    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;

    useEffect(()=>{
        window.scroll({
            behavior:'smooth',
            top:0
        })
       if(banner){
        banner.on('pointerUp', function (event, pointer) {
            banner.player.play();
        });

        banner.on('select',()=>{
            banner.player.play();
        });
        
       }
       

       if(liputan){
           liputan.on('ready',function(event,pointer){
               liputan.select(2)
           })
       }
       window.scroll({
            behavior:'smooth',
            top:0
        })
    },[banner,liputan]);

    return(
        <>

        {/* MODAL VIDEO */}
        <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={video}
        className="video__modal"
        onClose={()=>setVideo(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        >
        <Fade in={video}>
            <iframe title="MOSTRANS Video" className="video__content"  width="560" height="315" src="https://www.youtube.com/embed/VT8wxzCpC_Y" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </Fade>
      </Modal>
        <div className="beranda">
        <Flickity flickityRef={e=>setBanner(e)}
        options={{
            autoPlay:true,
        }}>
            <BannerTruck />
            <BannerKarir />
            <BannerBisnis />
        </Flickity>
        </div>
        
        <section className="h-about">
            <div className="h-about__top">
                <div className="h-about__text">
                    <div className="h-about__title">{lang.navAbout}</div>
                    <div className="h-about__info">
                        {lang.homeAbout_p1}
                        <br /> <br/>
                        {lang.homeAbout_p2}
                        <br /> <br/>
                        <button className="h-about__btn" onClick={()=> history.push('/tentang')}>{lang.btnReadMore}</button>
                    </div>
                </div>
                <div className="h-about__vid" onClick={()=>setVideo(true)}>
                    <div className="h-about__plyBtn">
                        <img src={playBtn.default} alt=""/>
                    </div>
                </div>
            </div>
            <div className="h-about__bottom">
                <div className="h-about__btm-title">{lang.homeAboutGrowing}</div>
                <div className="h-about__items">
                <div className="h-about__item">
                    <div className="h-about__number">50+</div>
                    <div className="h-about__name">{lang.txtShipper}</div>
                </div>

                <div className="h-about__item">
                    <div className="h-about__number">40+</div>
                    <div className="h-about__name">{lang.txtTransporters}</div>
                </div>

                <div className="h-about__item">
                    <div className="h-about__number">60.000+</div>
                    <div className="h-about__name">{lang.txtOY}</div>
                </div>

                <div className="h-about__item">
                    <div className="h-about__number">99.99%</div>
                    <div className="h-about__name">{lang.txtOF}</div>
                </div>

                <div className="h-about__item">
                    <div className="h-about__number">1.400+</div>
                    <div className="h-about__name">{lang.txtPoints}</div>
                </div>

                <div className="h-about__item">
                    <div className="h-about__number">5.000+</div>
                    <div className="h-about__name">{lang.txtTrucks}</div>
                </div>
                </div>
            </div>
        </section>
        <section className="h-solutions">
            <div className="h-solutions__title">{lang.homeSolutionsTitle}</div>
            <div className="h-solutions__text">
            {lang.homeSolution_p1}
                <br/> <br/>
                {lang.homeSolution_p2}
                <br/><br/>
                {lang.homeSolution_p3}
                <br/><br/>
                <button className="h-solutions__btn" onClick={()=> history.push('/solusi')}>{lang.btnReadMore}</button>
            </div>
            <a href="https://play.google.com/store/apps/developer?id=Mostrans&hl=in" rel="noopener noreferrer" target="_blank"><img src={laptop.default} alt="" className="h-solutions__laptop"/></a>
        </section>
        <section className="liputan">
            <div className="liputan__title">{lang.txtLiputan}</div>
            <div className="liputan__items">
                <Flickity options={{
                    pageDots:false
                }} 
                flickityRef={e=>setLiputan(e)}>
                    <Liputan src={liputanAntara.default} url='https://www.antarafoto.com/bisnis/v1570692609/peluncuran-aplikasi-transportasi-digital-mostrans' />
                    <Liputan src={liputanBisnis.default} url='https://foto.bisnis.com/view/20191010/1157893/aplikasi-transportasi-digital-mostrans' />
                    <Liputan src={liputanGatra.default} url='https://www.gatra.com/detail/news/450228/kesehatan/-enseval-keluarkan-platform-baru-di-bidang-transportasi' />
                    <Liputan src={liputanPowercom.default} url="https://powercommerce.asia/enseval-luncurkan-platform-omni-channel-logistik/" />
                    <Liputan src={liputanPressrelease.default} url="https://pressrelease.kontan.co.id/release/kalbe-grup-luncurkan-platform-digital-transportasi-pertama-di-indonesia-untuk-industri-kesehatan" />
                    <Liputan src={liputanRepublika.default} url="https://republika.co.id/berita/pz78g95417000/kalbe-luncurkan-platform-digital-buat-layani-logistik" />
                    <Liputan src={liputanSwa.default} url="https://swa.co.id/swa/trends/technology/platform-mostrans-fokus-layani-produk-kesehatan"/>
                    <Liputan src={liputanUpperline.default} url="https://upperline.id/post/kalbe-group-kenalkan-platform-transportasi-digital-kesehatan" />
                    <Liputan src={liputanWarta.default} url="https://www.wartaekonomi.co.id/read251039/kalbe-luncurkan-platform-digital-buat-layani-logistik.html"/>
                </Flickity>
            </div>
        </section>
        <section className="h-contact">
            <div className="h-contact__head">
                <img src={supportIcon.default} alt="" className="h-contact__icon" />
                <div>
                    <div className="h-contact__title">{lang.homeContactTitle}</div>
                    <div className="h-contact__text">{lang.homeContactTxt}</div>
                </div>
            </div>
            <div className="h-contact__content">
                <ContactForm/>
                <div className="h-contact__map">
                    <Maps/>
                </div>
            </div>
        </section>
        </>
    )
}   

export default Beranda;