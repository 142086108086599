//mostrans logo
export  const mostransLogo = require('./assets/logo-mostrans.png');

//icon
export const callIcon = require('./assets/call.png');
export const mailIcon = require('./assets/mail.png');
export const waIcon = require('./assets/wa.png');
export const chatIcon = require('./assets/chat.png');
export const fbIcon = require('./assets/facebook.png');
export const igIcon = require('./assets/instagram.png');
export const gmapIcon = require('./assets/gmap.png');
export const linkedInIcon = require('./assets/linkedin.png');
export const nextBtn = require('./assets/next-button.png');
export const playBtn = require('./assets/play-button.png');
export const prevBtn = require('./assets/prev-button.png');
export const supportIcon = require('./assets/support.png');
export const playstoreIcon = require('./assets/playstore.png');
export const supportIcon2 = require('./assets/support-icon.png')

//item
export const laptop = require('./assets/laptop-playstore.png');
export const controlTower = require('./assets/Control tower.png');
export const demand = require('./assets/Demand.png');
export const graph = require('./assets/graph.png');
export const live = require('./assets/live.png');
export const quality = require('./assets/quality.png');
export const shipperPage =  require('./assets/Shipper Page - Juli_command center.png');
export const system = require('./assets/system.png');
export const thought = require('./assets/thought.png');
export const trust = require('./assets/trust (1).png');
export const truck = require('./assets/truck.PNG');
export const truck3 = require('./assets/3truck.PNG');

//bg
export const background = require('./assets/background.jpg');
export const background2 = require('./assets/background-2.PNG');
export const background_full = require('./assets/background-full.jpg')


//banner
export const bannerKarir = require('./assets/banner-karir.jpg');
export const bannerTruck = require('./assets/banner-truck.jpg');
export const bannerBisnis = require('./assets/banner1.jpg');
//mini
export const bannerTruckMini = require('./assets/banner-truck-mini.PNG')
export const bannerBisnisMini = require('./assets/banner1-mini.PNG');
export const bannerKarirMini = require('./assets/banner-karir-mini.PNG');

//bg page
export const bgShipper = require('./assets/bg-shipper.jpg');
export const bgSolusi = require('./assets/bg-solusi.jpg');
export const bgTentang = require('./assets/bg-tentang.jpg');
export const bgVideo = require('./assets/bg-video.jpg');
export const bgKontak = require('./assets/bg-kontak.jpg');
export const thx1 = require('./assets/thx-1.jpg');
export const thx2 = require('./assets/thx-2.jpg');
//mini
export const bgTentangMini = require('./assets/bg-tentang-mini.PNG');
export const bgSolusiMini = require('./assets/bg-solusi-mini.PNG');
export const bgShipperMini = require('./assets/bg-shipper-mini.PNG');

//choice
export const choiceShipper = require('./assets/choice-shipper.jpg');
export const choiceTransporter = require('./assets/choice-transporter.jpg');

//liputan
export const liputanAntara = require('./assets/liputan-antara.png');
export const liputanBisnis = require('./assets/liputan-bisnis.png');
export const liputanGatra = require('./assets/liputan-gatra.png');
export const liputanPowercom = require('./assets/liputan-powercom.png');
export const liputanPressrelease = require('./assets/liputan-pressrelease.png');
export const liputanRepublika = require('./assets/liputan-republika.png');
export const liputanSwa = require('./assets/liputan-swa.png');
export const liputanUpperline = require('./assets/liputan-upperline.png');
export const liputanWarta = require('./assets/liputan-warta.png');

//solusi
export const solusi1 = require('./assets/solusi-1.PNG');
export const solusi2 = require('./assets/solusi-2.PNG');
export const solusi3 = require('./assets/solusi-3.PNG');
export const solusi4 = require('./assets/solusi-4.PNG');

//shipper
export const shipper1 = require('./assets/shipper1.png');
export const shipper2 = require('./assets/shipper2.png');
export const shipper3 = require('./assets/shipper3.png');
export const shipper4 = require('./assets/shipper4.png');
export const shipper5 = require('./assets/shipper5.png');

//trans
export const transporter1 = require('./assets/trans1.PNG');
export const transporter3 = require('./assets/trans3.PNG');

//fonts
export const SFUIRegular = require('./assets/fonts/SFUIText-Regular.ttf');
export const SFUIBold = require('./assets/fonts/SFUIText-Bold.ttf');

