import React, { useContext, useEffect } from 'react';
import {supportIcon2} from '../../assets';
import ContactForm from '../../components/ContactForm/ContactForm';
import Maps from '../../components/Maps/Maps';
import { LanguageContext } from '../../context/LanguageContext';

const Kontak = ()=>{
    useEffect(()=>{
        window.scroll({
            behavior:'smooth',
            top:0
        })
    },[]);
    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;
    return(
       <>
       <div className="head head-kontak">
           <img src={supportIcon2.default} alt=""/>
           <div className="head-content">
                <div className="head__title">{lang.kontakHeroTitle}</div>
                <div className="head__info">
                    {lang.kontakHeroInfo}
                </div>
           </div>
       </div>
       <div className="kontak">
           <ContactForm />
           <Maps top={true} />
       </div>
       </>
    )
}

export default Kontak;