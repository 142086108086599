import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';

const Syarat = ()=>{
    useEffect(()=>{
        window.scroll({
            behavior:'smooth',
            top:0
        })
    },[]);
    const history = useHistory();
    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;
    return(
        <div className="syarat">
            <div className="syarat__title">{lang.txtSyarat}</div>
            <div className="syarat__info">
                <div className="syarat__text">
                    Ketentuan Penggunaan <br/>
                    Berikut adalah syarat dan ketentuan yang berlaku pada saat menggunakan Layanan MOSTRANS (“MOSTRANS” atau “Kami”) yang disediakan oleh Kami dan/atau Transporter selaku mitra kerja kami (sebagaimana masing-masing akan didefinisikan di bawah ini). <br/>
                    Dengan mendaftar dan/atau menggunakan situs MOSTRANS (Website) dan/atau aplikasi online MOSTRANS (Aplikasi), Anda menyatakan bahwa Anda telah membaca, mengerti dan setuju untuk terikat secara hukum oleh syarat dan ketentuan ini dan dokumen-dokumen sebagaimana dimaksud di dalamnya.
                </div>

                <div className="syarat__text">
                    Definisi <br/>
                    <p>
                    “MOSTRANS” adalah penyedia jasa Platform Transportasi Logistik yang berfokus di produk dan/atau industri kesehatan pertama di Indonesia.
                    </p><br/>
                    <p>
                    “Transporter” adalah perorangan dan/atau Badan Hukum Indonesia dan/atau Badan Usaha Indonesia yang mendaftar dan/atau menggunakan jasa layanan MOSTRANS agar dapat menawarkan jasa kepada publik. 
                    </p><br/>
                    <p>
                    “Shipper” adalah orang perseroangan dan/atau Badan Hukum Indonesia dan/atau Badan Usaha Indonesia yang menggunakan jasa layanan MOSTRANS untuk melakukan pengiriman barang dan/atau muatan. 
                    </p><br/>
                    <p>
                    “Layanan MOSTRANS” adalah keseluruhan produk dan/atau jasa yang diberikan oleh MOSTRANS termasuk namun tidak terbatas pada perangkat lunak yang dioperasikan melalui website dan/atau Aplikasi.
                    </p><br/>
                    <p>
                    “Website” adalah situs MOSTRANS.co.id yang digunakan untuk mengakses dan menggunakan Layanan MOSTRANS.
                    </p><br/>
                    <p>
                    “Aplikasi” adalah aplikasi milik MOSTRANS yang digunakan untuk mengakses dan menggunakan Layanan MOSTRANS. 
                    </p><br/>
                    <p>
                    “Pengguna” adalah setiap pengguna jasa MOSTRANS, baik Transporter dan/atau Shipper baik melalui Website dan/atau Aplikasi.
                    </p><br/>
                    <p>
                    “Order” adalah pendaftarkan permintaan pengiriman dari Shipper yang akan dikelola MOSTRANS dan dipenuhi melalui Transporter. 
                    </p><br/>
                    
                </div>


                <div className="syarat__text">
                Penggunaan Website MOSTRANS <br/>
                    <p>
                    Ketentuan-ketentuan ini hanya berlaku untuk bagian-bagian dari situs Website yang tidak memerlukan sertifikat digital atau perangkat keamanan lainnya atau tindakan untuk akses.
                    </p><br/>
                    <p>
                    Pengunduhan dan/atau penggunaan Website dan Aplikasi terbebas dari biaya. MOSTRANS tidak memungut biaya pendaftaran dan/atau berlangganan kepada Pengguna.
                    </p><br/>
                    <p>
                    Pengguna yang telah mendaftar dapat bertindak sebagai Shipper dan/atau Transporter.
                    </p><br/>
                    <p>
                    Pendaftaran diri menjadi Pengguna dilakukan oleh Transporter dan/atau Shipper baik melalui Website dan/atau Aplikasi.   
                    </p><br/>
                    <p>
                    Pengguna mengisi dan melengkapi data diri dan/atau seluruh dokumen-dokumen yang diperlukan pada saat mendaftarkan diri. Pengguna menjamin bahwa seluruh data diri dan informasi yang disampaikan kepada MOSTRANS, termasuk namun tidak terbatas pada nomor telepon dan alamat surel adalah akurat berada di dalam penguasaan Pengguna.
                    </p><br/>
                    <p>
                    MOSTRANS akan melakukan verifikasi & validasi terhadap Pengguna yang telah mendaftarkan diri pada Website.
                    </p><br/>
                    <p>
                    Dalam hal terdapat perubahan data-data Pengguna, maka Pengguna wajib menyampaikan kepada MOSTRANS. Kami tidak bertanggung jawab atas kerugian yang terjadi dalam hal terdapat perubahan yang tidak diberitahukan kepada Kami. Syarat dan Ketentuan Pengiriman oleh Shipper
                    </p><br/>
                    <p>
                    Setiap Order dan juga perinciannya yang dilakukan oleh Shipper akan didaftarkan melalui Website dan/atau Aplikasi. Shipper dapat menentukan syarat pengiriman tertentu kepada Transporter. Dalam hal ini MOSTRANS tidak bertanggung jawab atas syarat pengiriman tersebut.
                    </p><br/>
                    <p>
                    Shipper dapat membatalkan Order setiap saat baik sesudah Order dibuat hingga kendaraan tiba di lokasi penjemputan. Pembatalan tersebut akan dikenakan biaya sesuai dengan kesepakatan dengan MOSTRANS. 
                    </p><br/>
                    <p>
                    Shipper harus memastikan bahwa barang-barang yang akan dikirimkan adalah bukan barang-barang yang tidak sah, berbahaya, rawan, tidak aman, tidak menyenangkan, dapat ditolak atau barang apapun yang dilarang untuk dibawa berdasarkan peraturan perundangan yang berlaku di Indonesia.
                    </p><br/>
                    <p>
                    Sebelum pengiriman dilakukan, Shipper harus memberitahu setiap tindakan pencegahan yang harus diterapkan untuk menangani muatan sesuai dengan sifatnya.
                    </p><br/>
                    <p>
                    Shipper bertanggung jawab sendiri atas setiap dan semua kewajiban yang terjadi atau diduga terjadi sebagai akibat barang-barang yang dikirim, termasuk namun tidak terbatas pada kerusakan harta benda, cedera badan atau kematian. Syarat dan Ketentuan Transporter
                    </p><br/>
                    <p>
                    Transporter sudah menyelesaikan proses induksi MOSTRANS dan sudah memberikan kepada MOSTRANS salinan perizinan Surat Izin Usaha Jasa Transportasi (SIUJPT) yang masih berlaku.
                    </p><br/>
                    <p>
                    Transporter harus menyediakan semua peralatan dan dokumen yang dipersyaratkan untuk pelaksanaan jasa pengiriman dan harus memelihara semua perlatan tersebut dalam keadaan yang baik.
                    </p><br/>
                    <p>
                    Transporter harus dengan segera melanjutkan untuk mengambil Order dalam waktu 60 menit hingga 120 menit sejak penerimaan Order. Jangka waktu penerimaan tersebut dapat diperpanjang atau dikurangi sesuai dengan kesepakatan antara Shipper dan Transporter.
                    </p><br/>
                    <p>
                    Transporter tidak boleh menuntut, dan dengan ini melepaskan hak pada tuntutan, gadai apapun dalam pengiriman apapun.
                    </p><br/>
                    <p>
                    Transporter dilarang menghubungi Shipper secara langsung untuk menjalankan Order tanpa melalui Website. Ketentuan Kerahasiaan Data Pribadi
                    </p><br/>
                    <p>
                    Setiap informasi yang terkandung di dalam Website dan/atau Aplikasi MOSTRANS adalah milik MOSTRANS sepenuhnya.
                    </p><br/>
                    <p>
                    MOSTRANS akan menjaga kerahasiaan seluruh data pribadi Pengguna yang diperoleh melalui penggunaan Layanan MOSTRANS.
                    </p><br/>
                    <p>
                    Setiap data pribadi yang dipelihara oleh MOSTRANS dilindungi dan tunduk kepada peraturan perundang-undangan yang berlaku.
                    </p><br/>
                    <p>
                    Setiap data pribadi yang diberikan oleh Pengguna akan digunakan oleh MOSTRANS untuk kepentingan verifikasi identitas pada saat penggunaan Layanan MOSTRANS melalui Website dan/atau Aplikasi MOSTRANS, memantau penyalahgunaan Website MOSTRANS, serta menginformasikan kepada Pengguna terkait produk, layanan, promosi dan lain-lain.
                    </p><br/>
                    <p>
                    Setiap data dan informasi tidak akan diberikan kepada pihak ketiga tanpa persetujuan dari Pengguna, kecuali apabila diperbolehkan oleh hukum atau otoritas yang berwenang.
                    </p><br/>
                </div>

                <div className="syarat__text">
                    Keamanan <br/>
                    <p>
                    MOSTRANS telah melakukan segala langkah keamanan yang dilakukan untuk memastikan keamanan sistem dan data, baik secara teknis, organisasi, maupun operasional.
                    </p><br/>
                    <p>
                    MOSTRANS dapat memutus akses ke Layanan MOSTRANS untuk sementara jika ada dugaan kebocoran atau pelanggaran keamanan.
                    </p><br/>
                    <p>
                    Dalam hal terjadi kebocoran atau pelanggaran, MOSTRANS akan memberitahu Pengguna melalui Website dan/atau Aplikasi mengenai langkah-langkah yang akan dan/atau telah dilakukan.
                    </p><br/>
                    <p>
                    Apabila Pengguna merasa ada kebocoran atau pelanggaran, Pengguna wajib melaporkan hal tersebut kepada MOSTRANS, dan MOSTRANS akan segera memblokir sementara akses pihak yang bersangkutan ke Website dan/atau Aplikasi. Syarat-Syarat Pembayaran
                    </p><br/>
                    <p>
                    Dalam hal MOSTRANS bertindak sebagai penghubung antara Shipper dan Transporter, Shipper akan membayar biaya pelaksanaan yang terdiri dari pelatihan di lokasi, pengembangan analitis yang telah ditetapkan oleh MOSTRANS.
                    </p><br/>
                    <p>
                    Dalam hal MOSTRANS bertindak mewakili Transporter, Shipper akan membayar biaya pengangkutan yang telah disetujui sebelumnya dengan MOSTRANS. Asuransi dan Kompensasi.
                    </p><br/>
                    <p>
                    MOSTRANS menyediakan pilihan kepada Shipper untuk menggunakan jasa asuransi pengiriman dari perusahaan asuransi mitra MOSTRANS, yang besarannya ditetapkan kemudian.   
                    </p><br/>
                    <p>
                    MOSTRANS dapat menawarkan kompensasi atas segala kehilangan, kerusakan atau penghancuran barang dan/atau muatan yang terjadi pada saat pengiriman. Besaran kompensasi harus sesuai dengan nilai modal barang dan/atau muatan yang berlaku dari Shipper dan disetujui oleh MOSTRANS. 
                    </p><br/>
                </div>

                <div className="syarat__text">
                    Disclaimer <br/>
                    <p>
                    MOSTRANS berkedudukan hanya sebagai penyedia produk ataupun jasa platform dan bukan menjadi penanggungjawab teknis, agen Transporter bukan pula sebagai penyelenggara kegiatan logistik. Tanggung jawab MOSTRANS kepada Pengguna terbatas pada penyediaan Layanan MOSTRANS kepada Pengguna melalui Website dan Aplikasinya.
                    </p><br/>
                    <p>
                    Segala bentuk kejadian tak terduga dan/atau kerugian yang yang timbul selama pengangkutan menjadi tanggung jawab Pengguna sepenuhnya.
                    </p><br/>
                    <p>
                    MOSTRANS tidak bertanggungjawab terhadap segala bentuk kerusakan/ kehilangan/ kerugian/ kematian yang diderita oleh Pengguna selama pengangkutan berlangsung dan/ atau disebabkan oleh Pengguna. Hak Kekayaan Intelektual
                    </p><br/>
                    <p>
                    Semua hak kekayaan intelektual, termasuk hak cipta, paten, merek dagang dan rahasia dagang dalam Website dan Aplikasi MOSTRANS dimiliki oleh MOSTRANS atau pemberi lisensi MOSTRANS. 
                    </p><br/>
                    <p>
                    Pemberian jasa ini tidak mengalihkan kepada anda atau pihak ketiga manapun, setiap hak, hak milik atau kepentingan dalam atau untuk hak kekayaan intelektual tersebut.
                    </p><br/>
                </div>

                <div className="syarat__text">
                    Force Majeure <br/>
                    <p>
                    Pengguna akan membebaskan MOSTRANS dari segala tuntutan apapun, dalam hal MOSTRANS tidak dapat melaksanakan perintah dari Pengguna baik sebagian maupun seluruhnya karena kejadian-kejadian atau sebab-sebab di luar kekuasaan atau kemampuan Bank termasuk namun tidak terbatas pada segala gangguan virus komputer atau sistem Trojan Horses atau komponen membahayakan yang dapat menggangu Layanan MOSTRANS, web browser atau komputer sistem MOSTRANS, karena bencana alam, perang, huru-hara, keadaan peralatan, sistem atau transmisi yang tidak berfungsi, gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah, serta kejadian-kejadian atau sebab-sebab lain di luar kekuasaan atau kemampuan MOSTRANS. 
                    </p><br/>
                </div>

                <div className="syarat__text">
                    Lain-lain <br/>
                    <p>
                    MOSTRANS dapat mengubah Syarat dan Ketentuan ini dari waktu ke waktu dan berhak untuk melakukannya tanpa memberikan pemberitahuan terlebih dahulu. Segala perubahan Syarat dan Ketentuan akan ditampilkan di Website MOSTRANS.
                    </p><br/>
                    <p>
                    Syarat dan Ketentuan ini tunduk kepada hukum Negara Republik Indonesia. 
                    </p><br/>
                    <p>
                    Setiap dan semua perbedaan pendapat, perselisihan, permintaan, tuntutan atau dasar gugatan apapun antara yang berhubungan dengan Syarat dan Ketentuan ini akan diselesaikan melalui musyawarah mufakat. Namun apabila musyawarah tidak mencapai mufakat maka harus diselesaikan melalui arbitrase yang mengikat dan rahasia. Arbitrase tunduk pada ketentuan BANI.
                    </p><br/>
                </div>
            </div>
            <div className="square-btn" onClick={()=>history.push('/')}>{lang.btnBackToHome}</div>
        </div>
    )
}

export default Syarat;