import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';

const Privasi = ()=>{
    useEffect(()=>{
        window.scroll({
            behavior:'smooth',
            top:0
        })
    },[]);
    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;
    const history = useHistory();
    return(
        <div className="syarat">
            <div className="syarat__title">{lang.txtPrivasi}</div>
            <div className="syarat__info">
                Kebijakan Privasi ini menjelaskan bagaimana MOSTRANS (“Kami” dan/atau “kami´) mengumpulkan, menggunakan, menyimpan, mengolah, menguasai, mengungkapkan, mentransfer dan melindungi Informasi Pribadi anda. Kebijakan Privasi ini berlaku bagi seluruh pengguna aplikasi, situs web (www.mostrans.co.id), layanan, atau produk (“Aplikasi”) kami, kecuali diatur pada kebijakan privasi yang terpisah. 
                <br/><br/>
                Mohon baca Kebijakan Privasi ini dengan seksama dan memastikan bahwa anda memahami bagaimana proses pengolahan data kami. Kecuali didefinisikan lain, semua istilah dengan huruf kapital yang digunakan dalam Kebijakan Privasi ini memiliki arti yang sama dengan yang tercantum dalam Ketentuan Penggunaan. 
                <br/><br/>
                1.Pengumpulan Informasi Kami mengumpulkan informasi dari Anda ketika Anda mendaftar pada situs kami, masuk ke akun Anda, melakukan pemesanan, ikut dalam kontes, dan/atau ketika Anda keluar. Data yang dikumpulkan mencakup nama Anda, alamat email, nomor telepon, dan/atau alamat. <br/> Selain itu, kami menerima dan merekam informasi secara otomatis dari komputer dan peramban Anda, termasuk alamat IP Anda, perlengakapan perangkat keras dan lunak Anda, dan halaman yang Anda minta. 
                <br/><br/>
                2. Penggunaan Informasi <br/>
                Segala informasi yang kami kumpulkan dari Anda dapat digunakan untuk: 
                <ul>
                    <li>Personalisasi pengalaman dan tanggapan Anda sesuai kebutuhan pribadi Anda</li>
                    <li>Menyediakan konten iklan yang disesuaikan</li>
                    <li>Meningkatkan situs web kami</li>
                    <li>Meningkatkan layanan pelanggan dan mendukung kebutuhan Anda</li>
                    <li>Menghubungi Anda lewat email</li>
                    <li>Mengatur kontes, promo, atau survei</li>
                </ul>
                <br/><br/>
                3. Privasi E-Dagang <br/>
                Kami adalah pemilik tunggal dari informasi yang dikumpulkan pada situs ini. Informasi pribadi Anda yang dapat diidentifikasi tidak akan dijual, dipertukarkan, ditransfer, atau diberikan kepada perusahaan lain dengan alasan apa pun juga, tanpa mendapatkan izin Anda, selain dari hanya semata-mata untuk memenuhi permohonan dan/ atau transaksi, misalnya untuk pengiriman pesanan. 
                <br/><br/>
                4. Pengungkapan Pihak Ketiga <br/> 
                Kami tidak menjual, memperdagangkan, atau pun memindahkan keluar dari pihak kami informasi pribadi Anda yang dapat diidentifikasi. Kami tidak mengikutkan pihak ketiga tepercaya yang membantu kami dalam mengoperasikan Website kami atau menyelenggarakan bisnis kami, selama semua pihak setuju untuk menjaga kerahasiaan informasi ini. 
                <br/><br/>
                Kami meyakini akan pentingnya berbagi informasi dalam rangka menyelidiki, mencegah, atau mengambil tindakan menyangkut aktivitas ilegal, dugaan penipuan, keadaan yang melibatkan ancaman terhadap keamanan fisik seseorang, pelanggaran atas syarat-syarat penggunaan kami, atau hal lain yang diwajibkan oleh hukum. Namun, informasi bukan privat dapat diberikan kepada pihak lain untuk pemasaran, pariwara, atau penggunaan lain. 
                <br/><br/>
                5. Proteksi Informasi Kami menerapkan berbagai langkah keamanan guna menjaga keamanan informasi pribadi Anda. Kami menggunakan enripsi teknologi mutakhir untuk memproteksi informasi sensitif yang dikirimkan secara online. 
                <br/><br/>
                6. Berhenti Berlangganan <br/> 
                Kami menggunakan alamat email yang Anda berikan untuk mengirimi Anda informasi dan pembaruan menyangkut pesanan Anda, berita berkala perusahaan, informasi mengenai produk, dll. Bila kapan saja Anda ingin berhenti menerima email kami di masa mendatang, kami memasukkan informasi mendetail informasi berhenti berlangganan di bawah tiap-tiap email. 
                <br/><br/>
                7. Persetujuan Dengan menggunakan situs kami, Anda setuju dengan kebijakan privasi kami.
            </div>
            <div className="square-btn" onClick={()=>history.push('/')}>{lang.btnBackToHome}</div>
        </div>
    )
}

export default Privasi;