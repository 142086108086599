import React, { useContext, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {mostransLogo,fbIcon,igIcon,linkedInIcon} from '../../assets';
import { LanguageContext } from '../../context/LanguageContext';

const Navigation = ()=>{
    const history = useHistory();

    //state
    const [nav,setNav] = useState(false);

    const {isEng,eng,ind,toggleLanguageEng,toggleLanguageInd} =  useContext(LanguageContext);
    const lang = isEng ? eng:ind;

    const changeLanguageSmall = (language)=>{
        if(language==='eng'){
            toggleLanguageEng();
        }else if (language==='ind'){
            toggleLanguageInd();
        }   
        setNav(!nav);
    }
    return(
        <>
        <div className="navigation">
            <img src={mostransLogo.default} alt="logo mostrans" className="navigation__logo" onClick={()=>history.push('/')}/>
            <div className="navigation__items navigation__big">
                <NavLink to="/" className="navigation__item" exact activeClassName="navigation--active">{lang.navHome}</NavLink>
                <NavLink to="/tentang" className="navigation__item" activeClassName="navigation--active">{lang.navAbout}</NavLink>
                <NavLink to="/solusi" className="navigation__item" exact activeClassName="navigation--active">{lang.navSolutions}</NavLink>
                <NavLink to="/mitra" className="navigation__item" activeClassName="navigation--active">{lang.navPartner}</NavLink>
                <NavLink to="/kontak" className="navigation__item" exact activeClassName="navigation--active">{lang.navContact}</NavLink> 
                {/* <NavLink to="/karir" className="navigation__item" activeClassName="navigation--active">{lang.navCareer}</NavLink>  */}
                <a href='https://mostrans.co.id/career/' target='_blank' className="navigation__item" activeClassName="navigation--active">{lang.navCareer}</a>
                <div className="navigation__divider"></div>  
                {/* LOGIN BIG PAGE */}
                <a className="navigation__login none" href="https://www.mostrans.co.id/loginv2">Login</a>
                <div className="navigation__sosmed">
                    <a href="https://www.linkedin.com/company/mostrans/" rel="noopener noreferrer" target="_blank">
                        <img src={linkedInIcon.default} alt="" />
                    </a>
                    <a href="https://www.instagram.com/mostrans.id/" rel="noopener noreferrer" target="_blank">
                        <img src={igIcon.default} alt="" />
                    </a>
                    <a href="https://www.facebook.com/mostrans.id/" rel="noopener noreferrer" target="_blank">
                        <img src={fbIcon.default} alt="" />
                    </a>
                </div>
                <div className="navigation__divider"></div> 
                <div className={isEng ? 'navigation__lang navigation__lang--active' : 'navigation__lang'}
                onClick={toggleLanguageEng}>Eng</div>
                <div className={!isEng ? 'navigation__lang navigation__lang--active' : 'navigation__lang'}
                onClick={toggleLanguageInd}>Ind</div>
            </div>
            <div className={nav?"navigation__small navigation--active":'navigation__small'}>
                <div className="navigation__sosmed">
                    <a href="https://www.linkedin.com/company/mostrans/" rel="noopener noreferrer" target="_blank">
                        <img src={linkedInIcon.default} alt="" />
                    </a>
                    <a href="https://www.instagram.com/mostrans.id/" rel="noopener noreferrer" target="_blank">
                        <img src={igIcon.default} alt="" />
                    </a>
                    <a href="https://www.facebook.com/mostrans.id/" rel="noopener noreferrer" target="_blank">
                    <img src={fbIcon.default} alt="" />
                    </a>
                </div>
                <div className="navigation__button"  onClick={()=>{setNav(!nav)}}>
                    <div className="navigation__toggle"></div>
                </div>
            </div>
        </div>

        <div className={nav?"navigation__sitems navigation--sactive":'navigation__sitems'}>
            <div className="navigation__items">
                <NavLink to="/" className="navigation__item" exact activeClassName="navigation--active"
                onClick={()=>{setNav(!nav)}}>{lang.navHome}</NavLink>
                <NavLink to="/tentang" className="navigation__item" activeClassName="navigation--active"
                onClick={()=>{setNav(!nav)}}>{lang.navAbout}</NavLink>
                <NavLink to="/solusi" className="navigation__item" exact activeClassName="navigation--active"
                onClick={()=>{setNav(!nav)}}>{lang.navSolutions}</NavLink>
                <NavLink to="/mitra" className="navigation__item" activeClassName="navigation--active"
                onClick={()=>{setNav(!nav)}}>{lang.navPartner}</NavLink>
                <NavLink to="/kontak" className="navigation__item" exact activeClassName="navigation--active"
                onClick={()=>{setNav(!nav)}}>{lang.navContact}</NavLink> 
                 <a href='https://mostrans.co.id/career/' target='_blank' className="navigation__item" activeClassName="navigation--active">{lang.navCareer}</a>
                {/* <NavLink to="/karir" className="navigation__item" activeClassName="navigation--active"
                onClick={()=>{setNav(!nav)}}>{lang.navCareer}</NavLink>  */}
                <div className="navigation__divider"></div> 
                <a href="https://mostrans.co.id/login/index" rel="noopener noreferrer" target="_blank" className="navigation__login">Login</a>
                <div className="navigation__slang">
                    <div className={isEng ? 'navigation__lang navigation__lang--active' : 'navigation__lang'}
                    onClick={()=>changeLanguageSmall('eng')}>Eng</div>
                    <div className={!isEng ? 'navigation__lang navigation__lang--active' : 'navigation__lang'}
                    onClick={()=>changeLanguageSmall('ind')}>Ind</div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Navigation;