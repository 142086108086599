import React, { useContext, useEffect } from 'react';
import {shipper2,transporter1,transporter3} from '../../assets';
import { LanguageContext } from '../../context/LanguageContext';

const Transporter = ()=>{

    useEffect(()=>{
        window.scroll({
            behavior:'smooth',
            top:0
        })
    },[]);

    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;

    return (
        <>
        <div className="head head-transporter">
            <div className="head__title">TRANSPORTER</div>
            <div className="head__info" dangerouslySetInnerHTML={{__html:lang.transporterHero}}>
            </div>
        </div>

        <div className="solusi">
            <div className="solusi__item">
                <div className="solusi__content">
                    <div className="solusi__title">{lang.transporterAdditional}</div>
                    <div className="solusi__info">
                    {lang.transporterAdditionalInfo}
                    </div>
                </div>
                <div className="solusi__img ">
                    <img src={transporter1.default} alt="" className="img-smaller" />
                </div>
            </div>

            <div className="solusi__item solusi--item">
                <div className="solusi__img">
                    <img src={shipper2.default} alt=""/>
                </div>
                <div className="solusi__content">
                    <div className="solusi__title">{lang.transporterTrack}</div>
                    <div className="solusi__info">
                    {lang.transporterTrackInfo}
                    </div>
                </div>
               
            </div>

            <div className="solusi__item">
                <div className="solusi__content">
                    <div className="solusi__title">{lang.transporterFaster}</div>
                    <div className="solusi__info">
                    {lang.transporterFasterInfo}
                    </div>
                </div>
                <div className="solusi__img">
                    <img src={transporter3.default} alt=""/>
                </div>
            </div>

            <div className="shipper__btm">
                <div className="shipper__title">{lang.txtJoin}</div>
                <div className="shipper__text" dangerouslySetInnerHTML={{__html:lang.txtJoinInfo}}>
                </div>
                {/* <div className="shipper__btn" onClick={toMitraForm}>Daftar Sekarang</div> */}
                <a className="shipper__btn" href="https://mostrans.co.id/login/SignUpTransporter">{lang.btnRegister}</a>
            </div>
        </div>
        </>
    )
}

export default Transporter;