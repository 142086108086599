import React, { useContext, useEffect } from 'react';
import {
    controlTower,
    demand,
    graph,
    live,
    quality,
    shipperPage,
    system,
    thought,
    trust,
    truck,
    truck3
} from '../../assets';
import { LanguageContext } from '../../context/LanguageContext';

const Item = ({src,title,info})=>{
    return(
        <div className="tentang-item">
            <img src={src} alt=""/>
            <div className="tentang-item__title">{title}</div>
            <div className="tentang-item__info">
                {info}
            </div>
        </div>
    )
}

const Tentang = ()=>{
    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;
    useEffect(()=>{
        window.scroll({
            behavior:'smooth',
            top:0
        })
    },[])
    return(
        <>
        <section className="head head-tentang">
            <div className="head__title">{lang.navAbout}</div>
            <div className="head__info" dangerouslySetInnerHTML={{__html:lang.tentangHero}}>
            </div>
        </section>
        
        <div className="tentang-container">
            <div className="visimisi">
                <h1 className="visimisi__title">{lang.vision} & {lang.mission}</h1>
                <h2 className="visimisi__subtitle">{lang.ourVision}</h2>
                <p className="visimisi__content">{lang.visionContent}</p>
                <h2 className="visimisi__subtitle">{lang.ourMission}</h2>
                <p className="visimisi__content">{lang.missionContent}</p>
            </div>
            <section className="tentang">
                <Item src={demand.default} title="On-Demand Services" info={lang.tentangOnDemand} />
                <Item src={thought.default} title="Ease of Mind" info={lang.tentangEaseOfMind} />
                <Item src={live.default} title="Live Chat Support" info={lang.tentangLiveChatSupport}/>
                <Item src={quality.default} title="Competitive Rates" info={lang.tentangCompetitveRates}/>
                <Item src={controlTower.default} title="Control Tower" info={lang.tentangControlTower} />
                <Item src={shipperPage.default} title="Command Center" info={lang.tentangCommandCenter} />
                <Item  src={system.default} title="ERP Integration" info={lang.tentangERPIntegration} />
                <Item src={truck.default} title="Direct Store Delivery" info={lang.tentangDirectStore} />
                <Item src={truck3.default} title="Wide Variety of Truck" info={lang.tentangWideVariety} />
                <Item src={graph.default} title="Data Analytics" info={lang.tentangDataAnalytics} />
                <Item src={trust.default} title="Reliable Delivery" info={lang.tentangReliable} />
                {/* <button className="tentang__btn" onClick={()=>history.push('/mitra/form')}>Daftar</button> */}
                <a href="https://mostrans.co.id/login/SignUpShipper" className="tentang__btn">{lang.btnRegister}</a>
            </section>
        </div>
        </>
    )
}

export default Tentang;