import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';

const TerimakasihDaftar = ()=>{
    const history = useHistory();
    const toBeranda = ()=>{
        history.push('/');
    }
    useEffect(()=>{
        window.scroll({
            behavior:'smooth',
            top:0
        })
    },[]);
    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;
    return(
        <div className="head head-tydaftar">
            <div className="head__title">{lang.txtThx}</div>
            <div className="head__info" dangerouslySetInnerHTML={{__html:lang.txtThxDaftar}}>
            </div>
            <div className="banner-truck__btn" onClick={toBeranda}>{lang.btnBackToHome}</div>
        </div>
    )
}
export default TerimakasihDaftar;