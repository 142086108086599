import React, { useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';
const ContactForm = ()=>{
    const history = useHistory();
    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;

    const [form,setForm] = useState({
        nama:'',
        perusahaan:'',
        partner:'',
        email:'',
        telephone:'',
        judul:'',
        pesan:''
    });

    const onSubmit =(e)=>{
        e.preventDefault();
        // SUBMIT TO SERVER
        console.log(form);
        history.push('/terima-kasih-pesan')
    }

    return(
        <form className="c-form" onSubmit={onSubmit}>
           <div className="c-form__divide">
            <TextField label={lang.labelName} className="full-width field" 
            inputProps={{className:'field'}}
            InputLabelProps={{className:'field'}}
            value={form.nama}
            onChange={(e)=> setForm({...form,nama:e.target.value})}
            required
            />
            <TextField label={lang.labelPerusahaan} className="full-width field"
            inputProps={{className:'field'}}
            InputLabelProps={{className:'field'}}
            value={form.perusahaan}
            onChange={e=>setForm({...form,perusahaan:e.target.value})}
            required
            />
           </div>
            <div className="mb-1">
            <TextField label={lang.labelBusinessPart} className="full-width field"
            inputProps={{className:'field'}}
            InputLabelProps={{className:'field'}}
            FormHelperTextProps={{className:'helper'}}
            helperText="Shipper / Transporter / etc"
            value={form.partner}
            onChange={e=>setForm({...form,partner:e.target.value})}
            required
            />
            </div>
           <div className="c-form__divide">
            <TextField label="Email" className="full-width field" 
            type="email"
            
            inputProps={{className:'field'}}
            InputLabelProps={{className:'field'}}
            value={form.email}
            onChange={e=>setForm({...form,email:e.target.value})}
            required
            />
            <TextField label={lang.labelPhone} className="full-width field"
            type="number"
            inputProps={{className:'field'}}
            InputLabelProps={{className:'field'}}
            value={form.telephone}
            onChange={e=>setForm({...form,telephone:e.target.value})}
            required
            />
           </div>

           <div className="mb-1">
            <TextField label={lang.labelJudul} className="full-width field"
            inputProps={{className:'field'}}
            InputLabelProps={{className:'field'}}
            value={form.judul}
            onChange={e=>setForm({...form,judul:e.target.value})}
            required
            />
            </div>

            <div className="mb-1">
            <TextField label={lang.labelPesan} className="full-width field"
            inputProps={{className:'field'}}
            InputLabelProps={{className:'field'}}
            value={form.pesan}
            onChange={e=>setForm({...form,pesan:e.target.value})}
            required
            />
            </div>
            <button className="c-form__btn" type="submit">{lang.btnKirim}</button>
        </form>
    )
}

export default ContactForm;