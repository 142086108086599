import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {shipper1,shipper2,shipper3,shipper4,shipper5} from '../../assets';
import { LanguageContext } from '../../context/LanguageContext';


const Shipper = ()=>{
    const history = useHistory();

    const toMitraForm = ()=>{
        history.push('/mitra/form?val=shipper');
    }

    useEffect(()=>{
        window.scroll({
            behavior:'smooth',
            top:0
        })
    },[]);
    const {isEng, eng,ind} = useContext(LanguageContext);
    const lang = isEng ? eng : ind;
    return (
        <>
        <div className="head head-shipper">
            <div className="head__title">SHIPPER</div>
            <div className="head__info" dangerouslySetInnerHTML={{__html:lang.shipperHero}}>
            
            </div>
        </div>

        <div className="solusi">
            <div className="solusi__item">
                <div className="solusi__content">
                    <div className="solusi__title">{lang.shipperFixedRate}</div>
                    <div className="solusi__info">
                    {lang.shipperFixedRateInfo}
                    </div>
                </div>
                <div className="solusi__img">
                    <img src={shipper1.default} alt=""/>
                </div>
            </div>

            <div className="solusi__item solusi--item">
                <div className="solusi__img">
                    <img src={shipper2.default} alt=""/>
                </div>
                <div className="solusi__content">
                    <div className="solusi__title">{lang.shipperVisibility}</div>
                    <div className="solusi__info">
                    {lang.shipperVisibilityInfo}
                    </div>
                </div>
               
            </div>

            <div className="solusi__item">
                <div className="solusi__content">
                    <div className="solusi__title">{lang.shipperReal}</div>
                    <div className="solusi__info">
                    {lang.shipperRealInfo}
                    </div>
                </div>
                <div className="solusi__img">
                    <img src={shipper3.default} alt=""/>
                </div>
            </div>

            <div className="solusi__item solusi--item">
                <div className="solusi__img">
                    <img src={shipper4.default} alt=""/>
                </div>
                <div className="solusi__content">
                    <div className="solusi__title">{lang.shipper24}</div>
                    <div className="solusi__info">
                    {lang.shipper24Info}
                    </div>
                </div>
               
            </div>

            <div className="solusi__item">
                <div className="solusi__content">
                    <div className="solusi__title">{lang.shipperConsolidate}</div>
                    <div className="solusi__info">
                    {lang.shipperConsolidateInfo}
                    </div>
                </div>
                <div className="solusi__img">
                    <img src={shipper5.default} alt=""/>
                </div>
            </div>
            <div className="shipper__btm">
                <div className="shipper__title">{lang.txtJoin}</div>
                <div className="shipper__text" dangerouslySetInnerHTML={{__html:lang.txtJoinInfo}}>
                </div>
                {/* <div className="shipper__btn" onClick={toMitraForm}>Daftar Sekarang</div> */}
                <a className="shipper__btn" href="https://mostrans.co.id/login/SignUpShipper">{lang.btnRegister}</a>
            </div>
        </div>

        
        </>
    )
}

export default Shipper;