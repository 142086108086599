import './sass/main.scss';
import Navigation from './components/Navigation/Navigation';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Beranda from './pages/Beranda/Beranda';
import Tentang from './pages/Tentang/Tentang';
import Solusi from './pages/Solusi/Solusi';
import Mitra from './pages/Mitra/Mitra';
import Kontak from './pages/Kontak/Kontak';
import Karir from './pages/Karir/Karir';
import Login from './pages/Login/Login';
import TerimakasihPesan from './pages/TerimakasihPesan/TerimakasihPesan';
import TerimakasihDaftar from './pages/TerimakasihDaftar/TerimakasihDaftar';
import LupaPassword from './pages/LupaPassword/LupaPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Shipper from './pages/Shipper/Shipper';
import Transporter from './pages/Transporter/Transporter';
import MitraForm from './pages/MitraForm/MitraForm';
import Apply from './pages/Apply/Apply';
import Syarat from './pages/Syarat/Syarat';
import Privasi from './pages/Privasi/Privasi';
import { useEffect, useState } from 'react';
import Spinner from './components/Spinner/Spinner';
import {mostransLogo,
  callIcon,
  mailIcon,
  waIcon,
  chatIcon,
  fbIcon,
  igIcon,
  gmapIcon,
  linkedInIcon,
  nextBtn,
  playBtn,
  prevBtn,
  supportIcon,
  playstoreIcon,
  supportIcon2,
  laptop,
  controlTower,
  demand,
  graph,
  live,
  quality,
  shipperPage,
  system,
  thought,
  trust,
  truck,
  truck3,
  background,
  background2,
  background_full,
  bannerKarir,
  bannerTruck,
  bannerBisnis,
  bgShipper,
  bgSolusi,
  bgTentang,
  bgVideo,
  bgKontak,
  thx1,
  thx2,
  choiceShipper,
  choiceTransporter,
  liputanAntara,
  liputanBisnis,
  liputanGatra,
  liputanPowercom,
  liputanPressrelease,
  liputanRepublika,
  liputanSwa,
  liputanUpperline,
  liputanWarta,
  solusi1,
  solusi2,
  solusi3,
  solusi4,
  shipper1,
  shipper2,
  shipper3,
  shipper4,
  shipper5,
  transporter1,
  transporter3,
  SFUIRegular,
  SFUIBold,
  bgTentangMini,
  bgSolusiMini,
  bgShipperMini,
  bannerTruckMini,
  bannerBisnisMini,
  bannerKarirMini
} from './assets';

function App() {
  const [isLoading,setIsLoading] = useState(true);

  const cacheImages = async (srcArray)=>{
    const promises = await srcArray.map(src=>{
        return new Promise ((resolve,reject)=>{
            const img = new Image();
            img.src = src;
            img.onload = resolve();
            img.onerror = reject();
        })
    });
    await Promise.all(promises);
    setIsLoading(false);
  }

  useEffect(()=>{
    const assets = [
      mostransLogo.default,
      callIcon.default,
      mailIcon.default,
      waIcon.default,
      chatIcon.default,
      fbIcon.default,
      igIcon.default,
      gmapIcon.default,
      linkedInIcon.default,
      nextBtn.default,
      playBtn.default,
      prevBtn.default,
      supportIcon.default,
      playstoreIcon.default,
      supportIcon2.default,
      laptop.default,
      controlTower.default,
      demand.default,
      graph.default,
      live.default,
      quality.default,
      shipperPage.default,
      system.default,
      thought.default,
      trust.default,
      truck.default,
      truck3.default,
      background.default,
      background2.default,
      background_full.default,
      bannerKarir.default,
      bannerTruck.default,
      bannerBisnis.default,
      bgShipper.default,
      bgSolusi.default,
      bgTentang.default,
      bgVideo.default,
      bgKontak.default,
      thx1.default,
      thx2.default,
      choiceShipper.default,
      choiceTransporter.default,
      liputanAntara.default,
      liputanBisnis.default,
      liputanGatra.default,
      liputanPowercom.default,
      liputanPressrelease.default,
      liputanRepublika.default,
      liputanSwa.default,
      liputanUpperline.default,
      liputanWarta.default,
      solusi1.default,
      solusi2.default,
      solusi3.default,
      solusi4.default,
      shipper1.default,
      shipper2.default,
      shipper3.default,
      shipper4.default,
      shipper5.default,
      transporter1.default,
      transporter3.default,
      SFUIRegular.default,
      SFUIBold.default,
      bgTentangMini.default,
      bgSolusiMini.default,
      bgShipperMini.default,
      bannerTruckMini.default,
      bannerBisnisMini.default,
      bannerKarirMini.default
    ];
    cacheImages(assets);
  },[])

  return (
    <>
    {
      isLoading ? <Spinner /> :
      <HashRouter>
        <div>
          <Navigation/>
          <Switch>
            <Route path="/" exact component={Beranda} />
            <Route path="/tentang" component={Tentang} />
            <Route path="/solusi" component={Solusi} />
            <Route path="/mitra" exact component={Mitra} />
            <Route path="/mitra/shipper" component={Shipper} />
            <Route path="/mitra/transporter" component={Transporter} />
            <Route path="/mitra/form" component={MitraForm} />
            <Route path="/kontak" component={Kontak} />
            <Route path="/karir" exact component={Karir} />
            <Route path="/karir/apply" exact component={Apply} />
            {/* LOGIN COMPONENT */}
            {/* <Route path="/login" exact component={Login} />
            <Route path="/login/lupa-password" exact  component={LupaPassword} />
            <Route path="/login/lupa-password/reset"  component={ResetPassword} /> */}
            {/* END LOGIN COMPONENT */}
            <Route path="/terima-kasih-pesan" component={TerimakasihPesan} />
            <Route path="/terima-kasih-daftar" component={TerimakasihDaftar} />
            <Route path="/syarat-ketentuan" component={Syarat} />
            <Route path="/privasi" component={Privasi} />
          </Switch>
          <Footer />
        </div>
      </HashRouter>
    }
    
    </>
    
  );
}

export default App;
